.MapContainer {
    height: 100vh;
    overflow: hidden;

    .MapRow {
        .MapContent {
            .MapImg {
                background-image: url('../../Assets/Images/mapa.jpg');
                background-repeat: no-repeat;
            }
        }
    }
}