// X-LARGE DEVICES [XL]
@media all and (min-width: 1200px) {
    .carousel {
        font-weight: 100;
        height: 100vh;
        .carousel-control-prev, .carousel-control-next{
            margin: auto auto;
        }
        .CarouselItem {
            img {
                height: 100vh;
            }
            .Mobile {
                display: none !important;
            }
        }
        .Carousel__heroImage {
            -webkit-filter: brightness(75%);
            filter:brightness(75%);
        }
        .H1Caption {
            height: 95vh;
            .word1 {
                font-size: 1.5em;
            }
        }
        .Caption {
            p {
                font-weight: 900;
                font-size: 1em;
                text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.3);
                backdrop-filter: blur(10px);
                border: 1px solid white;
                padding: 0.5em;
            }
        }
        .NavBar__center {
            z-index: 10;
            width: 100%;
            padding-top: 1.5em;
            font-size: 1.3em;
            li {
                a {
                    span p {
                        font-size: 0.6em;
                        font-weight: 700;
                    }
                }
            }
        }
    }
}

// LARGE DEVICES [LG]
@media all and (min-width: 993px) and (max-width: 1199px) {
    .carousel {
        font-weight: 100;
        height: 100vh;
        .carousel-control-prev, .carousel-control-next{
            margin: auto auto;
        }
        .CarouselItem {
            img {
                height: 100vh;
            }
            .drone {
                object-position: 100%;
            }
            .Mobile {
                display: none !important;
            }
        }
        .Carousel__heroImage {
            -webkit-filter: brightness(75%);
            filter:brightness(75%);
        }
        .H1Caption {
            height: 95vh;
            .word1 {
                font-size: 1.5em;
            }
        }
        .Caption {
            p {
                font-weight: 900;
                font-size: 1em;
                text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.3);
                backdrop-filter: blur(10px);
            }
        }
        .NavBar__center {
            z-index: 10;
            width: 100%;
            padding-top: 1.5em;
            font-size: 1.3em;
            li {
                a {
                    span p {
                        font-size: 0.6em;
                        font-weight: 700;
                    }
                }
            }
        }
    }
}

// MEDIUM DEVICES [MD]
@media all and (min-width: 769px) and (max-width: 992px) {
    .carousel {
        font-weight: 100;
        height: 100vh;
        .carousel-control-prev, .carousel-control-next{
            margin: auto auto;
        }
        .CarouselItem {
            img {
                height: 100vh;
            }
            .drone {
                object-position: 100%;
            }
            .Mobile {
                display: none !important;
            }
        }
        .Carousel__heroImage {
            -webkit-filter: brightness(75%);
            filter:brightness(75%);
        }
        .H1Caption {
            height: 95vh;
            .word1 {
                font-size: 1.5em;
            }
        }
        .Caption {
            p {
                font-weight: 900;
                font-size: 1em;
                text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.3);
                backdrop-filter: blur(10px);
            }
        }
        .NavBar__center {
            z-index: 10;
            width: 100%;
            padding-top: 1.5em;
            font-size: 1.3em;
            li {
                a {
                    span p {
                        font-size: 0.6em;
                        font-weight: 700;
                    }
                }
            }
        }
    }
}

// SMALL DEVICES [SM]
@media all and (min-width: 577px) and (max-width: 768px) {
    .carousel {
        font-weight: 100;
        height: 100vh;
        .carousel-control-prev, .carousel-control-next{
            margin: auto auto;
        }
        .CarouselItem {
            img {
                height: 100vh;
            }
            .drone {
                display: none;
            }
            .Mobile {
                object-position: 50% 0%;
            }
            .Pile {
                object-position: 50% 100%;
            }
        }
        .Carousel__heroImage {
            -webkit-filter: brightness(75%);
            filter:brightness(75%);
        }
        .H1Caption {
            height: 95vh;
            .word1 {
                font-size: 1.5em;
            }
        }
        .Caption {
            p {
                font-weight: 900;
                font-size: 1em;
                text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.3);
                backdrop-filter: blur(10px);
            }
        }
        .NavBar__center {
            z-index: 10;
            width: 100%;
            padding-top: 1.5em;
            font-size: 1.3em;
            li {
                a {
                    span p {
                        font-size: 0.6em;
                        font-weight: 700;
                    }
                }
            }
        }
    }
}

// XS DEVICES [-]
@media all and (max-width: 576px) {
    .carousel {
        font-weight: 100;
        height: 100vh;
        .carousel-control-prev, .carousel-control-next{
            margin: auto auto;
        }
        .CarouselItem {
            img {
                height: 100vh;
            }
            .drone {
                display: none;
            }
            .Mobile {
                object-position: 50% 0%;
            }
            .Pile {
                object-position: 50% 100%;
            }
        }
        .Carousel__heroImage {
            -webkit-filter: brightness(75%);
            filter:brightness(75%);
        }
        .H1Caption {
            height: 95vh;
            .word1 {
                font-size: 1.1em;
                line-height: 2em;
            }
        }
        .Caption {
            p {
                font-weight: 900;
                font-size: 1em;
                text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.3);
                backdrop-filter: blur(10px);
            }
        }
        .NavBar__center {
            z-index: 10;
            width: 100%;
            padding-top: 1.5em;
            font-size: 1.2em;
            li {
                a {
                    span p {
                        font-size: 0.6em;
                        font-weight: 700;
                    }
                }
            }
        }
    }
}