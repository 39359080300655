// X-LARGE DEVICES [XL]
@media all and (min-width: 1200px) {
    .SliderContainer {
        height: 55%;
        padding-top: 5%;
    }

    .Slider {
        height: 320px;
        margin-top: 1%;
        width: 100vw;
        padding-top: 2%;
    }

    .CardItem__container {
        height: 500px;
        width: 65% !important;
        margin-top: 6%;
        margin-bottom: 3%;

        .CardTitle {
            font-size: 1.3em;
        }
        .CardItem__content {
            .CardItem__image {
                height: 400px;
                width: 90%;
                margin-left: 5%;
                margin-top: 5%;
                margin-bottom: 8%;
                box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
            }

            .CardItem__description {
                // opacity: 0;
                width: 90%;
                height: 80%;
                color: white;
                padding-left: 5%;
                padding-right: 5%;
                p {
                    margin-bottom: 0 !important;
                    width: 100%;
                }
            }
        }
    }

    .slick-dots {
        height: 50px;
        top: 75vh;
    }
}

// LARGE DEVICES [LG]
@media all and (min-width: 993px) and (max-width: 1199px) {
    .SliderContainer {
        height: 55%;
        padding-top: 5%;
    }

    .Slider {
        height: 320px;
        margin-top: 1%;
        width: 100vw;
        padding-top: 2%;
    }

    .CardItem__container {
        height: 500px;
        width: 65% !important;
        margin-top: 6%;
        margin-bottom: 3%;

        .CardTitle {
            font-size: 1.3em;
        }
        .CardItem__content {
            .CardItem__image {
                height: 400px;
                width: 90%;
                margin-left: 5%;
                margin-top: 5%;
                margin-bottom: 8%;
                box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
            }

            .CardItem__description {
                // opacity: 0;
                width: 90%;
                height: 80%;
                color: white;
                padding-left: 5%;
                padding-right: 5%;
                p {
                    margin-bottom: 0 !important;
                    width: 100%;
                }
            }
        }
    }

    .slick-dots {
        height: 50px;
        top: 75vh;
    }
}

// MEDIUM DEVICES [MD]
@media all and (min-width: 769px) and (max-width: 992px) {
    .SliderContainer {
        height: 55%;
        padding-top: 5%;
    }

    .Slider {
        height: 320px;
        margin-top: 1%;
        width: 100vw;
        padding-top: 2%;
    }

    .CardItem__container {
        height: 500px;
        width: 65% !important;
        margin-top: 6%;
        margin-bottom: 3%;

        .CardTitle {
            font-size: 1.3em;
        }
        .CardItem__content {
            .CardItem__image {
                height: 400px;
                width: 90%;
                margin-left: 5%;
                margin-top: 5%;
                margin-bottom: 8%;
                box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
            }

            .CardItem__description {
                // opacity: 0;
                width: 90%;
                height: 80%;
                color: white;
                padding-left: 5%;
                padding-right: 5%;
                p {
                    margin-bottom: 0 !important;
                    width: 100%;
                    font-size: 0.9em;
                }
            }
        }
    }

    .slick-dots {
        height: 50px;
        top: 570px;
    }
}

// SMALL DEVICES [SM]
@media all and (min-width: 577px) and (max-width: 768px) {
    .SliderContainer {
        height: 55%;
        padding-top: 5%;
    }

    .Slider {
        height: 320px;
        margin-top: 1%;
        width: 100vw;
        padding-top: 2%;
    }

    .CardItem__container {
        height: 500px;
        width: 65% !important;
        margin-top: 6%;
        margin-bottom: 3%;

        .CardTitle {
            font-size: 1.3em;
        }
        .CardItem__content {
            .CardItem__image {
                height: 400px;
                width: 90%;
                margin-left: 5%;
                margin-top: 5%;
                margin-bottom: 8%;
                box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
            }

            .CardItem__description {
                // opacity: 0;
                width: 90%;
                height: 80%;
                color: white;
                padding-left: 5%;
                padding-right: 5%;
                p {
                    margin-bottom: 0 !important;
                    width: 100%;
                    font-size: 0.9em;
                }
            }
        }
    }

    .slick-dots {
        height: 50px;
        top: 570px;
    }
}

// XS DEVICES [-]
@media all and (min-width: 415px) and (max-width: 576px){
    .SliderContainer {
        height: 55%;
        padding-top: 5%;
    }

    .Slider {
        height: 320px;
        margin-top: 1%;
        width: 100vw;
        padding-top: 2%;
    }

    .CardItem__container {
        height: 500px;
        width: 65% !important;
        margin-top: 6%;
        margin-bottom: 3%;

        .CardTitle {
            font-size: 1.3em;
        }
        .CardItem__content {
            .CardItem__image {
                height: 400px;
                width: 90%;
                margin-left: 5%;
                margin-top: 5%;
                margin-bottom: 8%;
                box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
            }

            .CardItem__description {
                // opacity: 0;
                width: 90%;
                height: 80%;
                color: white;
                padding-left: 5%;
                padding-right: 5%;
                p {
                    margin-bottom: 0 !important;
                    width: 100%;
                    font-size: 0.9em;
                }
            }
        }
    }

    .slick-dots {
        height: 50px;
        top: 570px;
    }
}

// IPHONE 6/7/8 PLUS DEVICES [AN EXCEPTION]
@media all and (max-width: 414px) {
    .SliderContainer {
        height: 55%;
        padding-top: 5%;
    }

    .Slider {
        height: 320px;
        margin-top: 1%;
        width: 100vw;
        padding-top: 2%;
    }

    .CardItem__container {
        height: 500px;
        width: 65% !important;
        margin-top: 6%;
        margin-bottom: 3%;

        .CardTitle {
            font-size: 1.3em;
        }
        .CardItem__content {
            .CardItem__image {
                height: 400px;
                width: 90%;
                margin-left: 5%;
                margin-top: 5%;
                margin-bottom: 8%;
                box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
            }

            .CardItem__description {
                // opacity: 0;
                width: 90%;
                height: 80%;
                color: white;
                padding-left: 5%;
                padding-right: 5%;
                p {
                    margin-bottom: 0 !important;
                    width: 100%;
                    font-size: 0.9em;
                }
            }
        }
    }

    .slick-dots {
        height: 50px;
        top: 570px;
    }
}