.ImagesSlider {
    border: 3px solid white;

    .swiper-slide {
        overflow: hidden;
        position: relative;

        .BigImages {
            position: absolute;
            width: 100%;
            object-fit: cover;
            object-position: 50% 50%;
        }
    }

    .swiper-button-prev {
        color: rgb(255, 255, 255);
    }

    .swiper-button-next {
        color: rgb(255, 255, 255);
    }
}