// X-LARGE DEVICES [XL]
@media all and (min-width: 1200px) {
    .social-bar {
        right: 0;
        top: 80vh;
        font-size: 1.5rem;

        .icon {
            padding: 0.4rem;
        }

        .icon:first-child {
            border-radius: 1rem 0 0 0;
        }
        .icon:last-child {
            border-radius: 0 0 0 1rem;
        }

        .icon:hover {
            padding-right: 2rem;
            border-radius: 1rem 0 0 1rem;
        }
    }
}

// LARGE DEVICES [LG]
@media all and (min-width: 993px) and (max-width: 1199px) {
    .social-bar {
        right: 0;
        top: 80vh;
        font-size: 1.5rem;

        .icon {
            padding: 0.4rem;
        }

        .icon:first-child {
            border-radius: 1rem 0 0 0;
        }
        .icon:last-child {
            border-radius: 0 0 0 1rem;
        }

        .icon:hover {
            padding-right: 2rem;
            border-radius: 1rem 0 0 1rem;
        }
    }
}

// MEDIUM DEVICES [MD]
@media all and (min-width: 769px) and (max-width: 992px) {
    .social-bar {
        right: 0;
        top: 80vh;
        font-size: 1.5rem;

        .icon {
            padding: 0.4rem;
        }

        .icon:first-child {
            border-radius: 1rem 0 0 0;
        }
        .icon:last-child {
            border-radius: 0 0 0 1rem;
        }

        .icon:hover {
            padding-right: 2rem;
            border-radius: 1rem 0 0 1rem;
        }
    }
}

// SMALL DEVICES [SM]
@media all and (min-width: 577px) and (max-width: 768px) {
    .social-bar {
        right: 0;
        top: 80vh;
        font-size: 1.5rem;

        .icon {
            padding: 0.4rem;
        }

        .icon:first-child {
            border-radius: 1rem 0 0 0;
        }
        .icon:last-child {
            border-radius: 0 0 0 1rem;
        }

        .icon:hover {
            padding-right: 2rem;
            border-radius: 1rem 0 0 1rem;
        }
    }
}

// XS DEVICES [-]
@media all and (min-width: 415px) and (max-width: 576px){
    .social-bar {
        right: 0;
        top: 80vh;
        font-size: 1.3rem;

        .icon {
            padding: 0.4rem;
        }

        .icon:first-child {
            border-radius: 1rem 0 0 0;
        }
        .icon:last-child {
            border-radius: 0 0 0 1rem;
        }

        .icon:hover {
            padding-right: 2rem;
            border-radius: 1rem 0 0 1rem;
        }
    }
}

// IPHONE 6/7/8 PLUS DEVICES [AN EXCEPTION]
@media all and (max-width: 414px) {
    .social-bar {
        right: 0;
        top: 80vh;
        font-size: 1.1rem;

        .icon {
            padding: 0.4rem;
        }

        .icon:first-child {
            border-radius: 1rem 0 0 0;
        }
        .icon:last-child {
            border-radius: 0 0 0 1rem;
        }

        .icon:hover {
            padding-right: 2rem;
            border-radius: 1rem 0 0 1rem;
        }
    }
}