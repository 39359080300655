.ActivitiesContainer {
    display: flex;
    overflow: hidden;

    .ActivitiesRow {
    display: flex;
    }

    .ActivitiesTitle {
        font-family: amalfi;
        text-transform: capitalize;
        color: white;
    }
}