.NavBarContainer {
    position: absolute;
    z-index: 10;

    .NavBarRow {
        display: flex;
        align-items: center;

        .NavBarLeftContent, .NavBarRightContent {
            ul {
                display: flex;
                align-items: center;
                justify-content: space-evenly;
                list-style: none;
                li {
                    color: white;
                    padding-bottom:10px;
                    .NavBarLink {
                        position: relative;
                        color: white;
                        text-decoration: none;
                        text-transform: uppercase;
                        transition: all 0.3s;
                        font-weight: bold;
                        cursor: pointer;
                    }
                    .NavBarLink:hover {
                        padding-bottom: 4px;
                        border-bottom: 1px solid white;
                    }
                    button {
                        position: relative;
                        transition: all 0.3s;
                        background-color: transparent;
                        color: white;
                        border: none;
                        font-weight: 900;
                        font-size: 1em;
                        text-transform: uppercase;
                        padding: 4px !important;
                        height: 30px;
                    }
                    button:hover {
                        padding-bottom: 4px;
                        border-bottom: 1px solid white;
                    }
                }
                li:hover {
                    --p:calc(100% + 40px);
                    transition:0.8s linear;
                }
            }
        }
    }
}