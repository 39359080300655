// X-LARGE DEVICES [XL]
@media all and (min-width: 1200px) {
    .GalleryImgContainer {
        height: 100vh;

        .GalleryWrapper {
            grid-template-columns: repeat(auto-fill,minmax(250px, 1fr));
            grid-auto-rows: minmax(100px, auto);
            gap: 11px;
            padding: 6%;
            height: 100vh;
            max-width: 1400px;

            a {
                .GridItem {
                    border-radius: 4px;
                    transition: all 200ms ease-in-out;
                    width: 100%;
                    height: 100%;
                }
                .GridItem:hover {
                    filter: opacity(0.8);
                    transform: scale(1.02);
                }
            }
        }
    }
}

// LARGE DEVICES [LG]
@media all and (min-width: 993px) and (max-width: 1199px) {
    .GalleryImgContainer {
        height: auto;

        .GalleryWrapper {
            grid-template-columns: repeat(auto-fill,minmax(250px, 1fr));
            grid-auto-rows: minmax(100px, auto);
            gap: 11px;
            padding: 6%;
            height: 250vh;
            max-width: 1400px;

            a {
                .GridItem {
                    border-radius: 4px;
                    transition: all 200ms ease-in-out;
                    width: 100%;
                    height: 100%;
                }
                .GridItem:hover {
                    filter: opacity(0.8);
                    transform: scale(1.02);
                }
            }
        }
    }
}

// MEDIUM DEVICES [MD]
@media all and (min-width: 769px) and (max-width: 992px) {
    .GalleryImgContainer {
        height: auto;

        .GalleryWrapper {
            grid-template-columns: repeat(auto-fill,minmax(250px, 1fr));
            grid-auto-rows: minmax(100px, auto);
            gap: 11px;
            padding: 6%;
            height: 200vh;
            max-width: 1400px;

            a {
                .GridItem {
                    border-radius: 4px;
                    transition: all 200ms ease-in-out;
                    width: 100%;
                    height: 100%;
                }
                .GridItem:hover {
                    filter: opacity(0.8);
                    transform: scale(1.02);
                }
            }
        }
    }
}

// SMALL DEVICES [SM]
@media all and (min-width: 577px) and (max-width: 768px) {
    .GalleryImgContainer {
        height: auto;

        .GalleryWrapper {
            grid-template-columns: repeat(auto-fill,minmax(250px, 1fr));
            grid-auto-rows: minmax(100px, auto);
            gap: 11px;
            padding: 6%;
            height: 250vh;
            max-width: 1400px;

            a {
                .GridItem {
                    border-radius: 4px;
                    transition: all 200ms ease-in-out;
                    width: 100%;
                    height: 100%;
                }
                .GridItem:hover {
                    filter: opacity(0.8);
                    transform: scale(1.02);
                }
            }
        }
    }
}

// XS DEVICES [-]
@media all and (min-width: 415px) and (max-width: 576px){
    .GalleryImgContainer {
        height: auto;

        .GalleryWrapper {
            grid-template-columns: repeat(auto-fill,minmax(250px, 1fr));
            grid-auto-rows: minmax(100px, auto);
            gap: 11px;
            padding: 6%;
            height: 250vh;
            max-width: 1400px;

            a {
                .GridItem {
                    border-radius: 4px;
                    transition: all 200ms ease-in-out;
                    width: 100%;
                    height: 100%;
                }
                .GridItem:hover {
                    filter: opacity(0.8);
                    transform: scale(1.02);
                }
            }
        }
        .gallery-lightboxes .image-lightbox .image-lightbox-wrapper img {
            margin: 0 auto;
            max-height: 70vh;
            width: 301px;
        }
    }
}

// IPHONE 6/7/8 PLUS DEVICES [AN EXCEPTION]
@media all and (max-width: 414px) {
    .GalleryImgContainer {
        height: auto;

        .GalleryWrapper {
            grid-template-columns: repeat(auto-fill,minmax(250px, 1fr));
            grid-auto-rows: minmax(100px, auto);
            gap: 11px;
            padding: 6%;
            height: 250vh;
            max-width: 1400px;

            a {
                .GridItem {
                    border-radius: 4px;
                    transition: all 200ms ease-in-out;
                    width: 100%;
                    height: 100%;
                }
                .GridItem:hover {
                    filter: opacity(0.8);
                    transform: scale(1.02);
                }
            }
        }
        .gallery-lightboxes .image-lightbox .image-lightbox-wrapper img {
            margin: 0 auto;
            max-height: 70vh;
            width: 300px;
        }
    }
}