.GalleryImgContainer {
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;

    .GalleryWrapper {
        display: grid;
        grid-auto-flow: dense;
        overflow: hidden;
        justify-content: center;

        a {
            .GridItem {
                display: flex;
                align-items: center;
                justify-content: center;
                object-fit: cover;
                cursor: pointer;
              }
              .One {
                object-position: 50% 70%;
              }
              .Two {
                object-position: 50% 60%;
              }
              .Five {
                object-position: 50% 60%;
              }
              .Six {
                object-position: 50% 65%;
              }
              .Seven {
                object-position: 50% 80%;
              }
              .Eight {
                object-position: 50% 70%;
              }
              .Twelve {
                object-position: 50% 65%;
              }
              .Thirteen {
                object-position: 50% 88%;
              }
        }
    }
}

@media (min-width: 600px) {
    .Wide {
        grid-column: span 2;
    }
    .Tall {
        grid-row: span 3;
    }}


// LIGHTBOX
.gallery-lightboxes .image-lightbox {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0ms ease-in-out;
    overflow: hidden;
    z-index: 10;
}
.gallery-lightboxes .image-lightbox:target {
    opacity: 1;
    visibility: visible;
  }
  .gallery-lightboxes .image-lightbox:target .image-lightbox-wrapper {
    opacity: 1;
    transform: scale(1, 1) translateY(0);
  }
  .gallery-lightboxes .image-lightbox .image-lightbox-wrapper {
    transform: scale(0.95, 0.95) translateY(-30px);
    transition: opacity 500ms ease-in-out, transform 500ms ease-in-out;
    opacity: 0;
    margin: 1em auto;
    max-width: 100%;
    display: inline-block;
    position: relative;
    border: 2px solid white;
  }
  .gallery-lightboxes .image-lightbox .image-lightbox-wrapper .close {
    width: 1.5em;
    height: 1.5em;
    background: #000;
    color: #fff;
    font-weight: bold;
    text-decoration: none;
    border-radius: 50%;
    box-shadow: 0 0 0 2px white inset, 0 0 5px rgba(0, 0, 0, 0.5);
    position: absolute;
    right: -1em;
    top: -1em;
  }
  .gallery-lightboxes .image-lightbox .image-lightbox-wrapper .close:before {
    content: "";
    display: block;
    width: 10px;
    height: 2px;
    background: #fff;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -1px 0 0 -5px;
    transform: rotate(-45deg);
  }
  .gallery-lightboxes .image-lightbox .image-lightbox-wrapper .close:after {
    content: "";
    display: block;
    width: 10px;
    height: 2px;
    background: #fff;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -1px 0 0 -5px;
    transform: rotate(45deg);
  }
  .gallery-lightboxes .image-lightbox .image-lightbox-wrapper .arrow-left {
    position: absolute;
    top: 0;
    right: 50%;
    bottom: 0;
    left: 0;
  }
  .gallery-lightboxes .image-lightbox .image-lightbox-wrapper .arrow-left:before {
    content: "";
    display: inline-block;
    width: 20px;
    height: 20px;
    border: 2px solid #fff;
    border-bottom: 0;
    border-right: 0;
    border-radius: 4px 0 0 0;
    position: absolute;
    top: 50%;
    right: 100%;
    cursor: pointer;
    transform: rotate(-45deg) translateY(-50%);
  }
  .gallery-lightboxes .image-lightbox .image-lightbox-wrapper .arrow-right {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 50%;
  }
  .gallery-lightboxes .image-lightbox .image-lightbox-wrapper .arrow-right:before {
    content: "";
    display: block;
    width: 20px;
    height: 20px;
    border: 2px solid #fff;
    border-bottom: 0;
    border-left: 0;
    border-radius: 0 4px 0 0;
    position: absolute;
    top: 50%;
    left: 100%;
    cursor: pointer;
    transform: rotate(45deg) translateY(-50%);
  }
  .gallery-lightboxes .image-lightbox .image-lightbox-wrapper img {
    margin: 0 auto;
    max-height: 70vh;
  }
