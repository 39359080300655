.TheVillaContainer {
    overflow: hidden;
    display: flex;

    .TheVillaRow {
        display: flex;
        align-items: center;

        .TheVillaBkgColor {
            background-color: rgb(187, 182, 169);
            position: absolute;
        }
        .TheVillaContent {
            display: flex;
            align-items: center;
            color: white;

            .AlignmentDiv{
                .TheVillaTextTitle {
                    text-align: left;
                        h3 {
                            font-family: amalfi;
                            text-transform: capitalize;
                        }
                }
                .TheVillaLists {
                    display: flex;
                    text-align: left;
                    font-weight: bold;
                    .TheVillaList, .TheVillaListMobile {
                        list-style: none;
                    }
                }
            }
        }
    }
}