// X-LARGE DEVICES [XL]
@media all and (min-width: 1200px) {
    .ContactContainer {
        height: 100vh;

        .ContactRow {
            height: 90vh;
            width: 85vw;
            border-radius: 5px;
            .ContactHowToArrive {
                padding: 0 !important;
                height: 90vh;

                .ContactTextContent {
                    width: 100%;
                    padding: 3em;

                    h3 {
                        font-size: 1.7em;
                        padding-bottom: 2%;
                    }
                    .HowToArriveText {
                        margin-top: 15px;
                    }
                }
            }

            .ContactForm {
                padding: 0 !important;
                height: 90vh;

                .ContactFormContent {
                    height: 90vh;
                    width: 100%;
                }
            }
        }
    }
}

// LARGE DEVICES [LG]
@media all and (min-width: 993px) and (max-width: 1199px) {
    .ContactContainer {
        height: 200vh;

        .ContactRow {
            height: auto;
            width: 85vw;
            border-radius: 5px;
            padding-bottom: 4em;
            .ContactHowToArrive {
                padding: 0 !important;
                height: 90vh;

                .ContactTextContent {
                    width: 100%;
                    padding: 3em;

                    h3 {
                        font-size: 1.7em;
                        padding-bottom: 2%;
                    }
                    .HowToArriveText {
                        margin-top: 15px;
                    }
                }
            }

            .ContactForm {
                padding: 0 !important;
                height: 90vh;

                .ContactFormContent {
                    height: 90vh;
                    width: 100%;
                }
            }
        }
    }
}

// MEDIUM DEVICES [MD]
@media all and (min-width: 769px) and (max-width: 992px) {
    .ContactContainer {
        height: auto;
        padding-top: 4em;
        padding-bottom: 4em;

        .ContactRow {
            height: auto;
            width: 85vw;
            border-radius: 5px;
            padding-top: 2em;
            padding-bottom: 4em;
            .ContactHowToArrive {
                padding: 0 !important;
                height: 90vh;

                .ContactTextContent {
                    width: 100%;
                    padding: 3em;

                    h3 {
                        font-size: 1.7em;
                        padding-bottom: 2%;
                    }
                    .HowToArriveText {
                        margin-top: 15px;
                    }
                }
            }

            .ContactForm {
                padding: 0 !important;
                height: 90vh;

                .ContactFormContent {
                    height: 90vh;
                    width: 100%;
                }
            }
        }
    }
}

// SMALL DEVICES [SM]
@media all and (min-width: 577px) and (max-width: 768px) {
    .ContactContainer {
        height: auto;
        padding-top: 4em;
        padding-bottom: 4em;

        .ContactRow {
            height: auto;
            width: 85vw;
            border-radius: 5px;
            padding-top: 2em;
            padding-bottom: 4em;
            .ContactHowToArrive {
                padding: 0 !important;
                height: auto;
                padding-top: 2em;
                padding-bottom: 4em;

                .ContactTextContent {
                    width: 100%;
                    padding: 3em;

                    h3 {
                        font-size: 1.7em;
                        padding-bottom: 2%;
                    }
                    .HowToArriveText {
                        margin-top: 15px;
                    }
                }
            }

            .ContactForm {
                padding: 0 !important;
                height: 90vh;

                .ContactFormContent {
                    height: 90vh;
                    width: 100%;
                }
            }
        }
    }
}

// XS DEVICES [-]
@media all and (min-width: 415px) and (max-width: 576px){
    .ContactContainer {
        height: auto;
        padding-top: 4em;
        padding-bottom: 4em;

        .ContactRow {
            height: auto;
            width: 85vw;
            border-radius: 5px;
            padding-bottom: 4em;

            .ContactHowToArrive {
                padding: 0 !important;
                height: auto;

                .ContactTextContent {
                    width: 100%;
                    padding: 3em;


                    h3 {
                        font-size: 1.7em;
                        padding-bottom: 2%;
                        line-height: 2em;
                    }
                    .HowToArriveText {
                        margin-top: 15px;
                    }
                }
            }

            .ContactForm {
                padding: 0 !important;
                height: 90vh;

                .ContactFormContent {
                    height: 90vh;
                    width: 100%;
                }
            }
        }
    }
}

// IPHONE 6/7/8 PLUS DEVICES [AN EXCEPTION]
@media all and (max-width: 414px) {
    .ContactContainer {
        height: auto;
        padding-top: 4em;
        padding-bottom: 4em;

        .ContactRow {
            height: auto;
            width: 85vw;
            border-radius: 5px;
            padding-bottom: 4em;

            .ContactHowToArrive {
                padding: 0 !important;
                height: auto;

                .ContactTextContent {
                    width: 100%;
                    padding: 3em;


                    h3 {
                        font-size: 1.7em;
                        padding-bottom: 2%;
                        line-height: 2em;
                    }
                    .HowToArriveText {
                        margin-top: 15px;
                    }
                }
            }

            .ContactForm {
                padding: 0 !important;
                height: 90vh;

                .ContactFormContent {
                    height: 90vh;
                    width: 100%;
                }
            }
        }
    }
}