.ContactFormContainer {
    .ContactFormRow {
        margin-top: 15px;
        display: flex;

        .ContactFormText {
            width: 100%;
            justify-content: center;

            label {
                text-align: left;
                width: 80%;
                justify-content: center;
            }

            input, .Message {
                border: 1px solid rgb(236, 236, 236);
                -webkit-appearance: none;
                -ms-appearance: none;
                -moz-appearance: none;
                appearance: none;
                padding: 10px;
                border-radius: 3px;
                width: 80%;
                outline: none;
                font-size: 14px;
                background-color: whitesmoke;
            }
            .Message {
                height: 9em;
            }
        }
        .CheckContent {
            width: 50%;

            .LabelCheck {
                width: 90%;
            }
            .CheckIn, .CheckOut {
                width: 90%;
            }
        }
        .Left {
            padding-left: 10%;
        }
        .Right {
            padding-right: 10%;
        }
    }
    .FlexRow {
        display: flex;
    }

    .FormButtons {
        padding: 10px;
        background-color: rgb(187, 182, 169);
        border: none;
        border-radius: 3px;
        width: 90%;
        outline: none;
        border: 1px solid white;
        text-decoration: none;
        color: whitesmoke;
        cursor: pointer;
        font-weight: bold;
        margin-top: 15px;
    }
    .FormButtons:hover {
        filter: opacity(0.8);
        transform: scale(1.02);
    }
}