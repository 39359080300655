.SliderContainer {
    background-color: rgb(187, 182, 169);
}

.Slider {
    display: flex;
    align-items: center;
}

.CardItem__container {
    position: relative;
    background-color: #fff;

    .CardItem__content {
        .CardItem__image {
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            overflow: hidden;
        }
        // IMAGES
        .spa {
            background-image: url('../../Assets/Images/masajes.jpg');
        }
        .yoga {
            background-image: url('../../Assets/Images/yoga.jpg');
        }
        .surfing {
            background-image: url('../../Assets/Images/surf.jpg');
        }
        .ride {
            background-image: url('../../Assets/Images/cabalgata.jpg');
        }
        .fishing {
            background-image: url('../../Assets/Images/pesca.jpg');
        }
        .chef {
            background-image: url('../../Assets/Images/chef.jpg');
        }
        .kitchen {
            background-image: url('../../Assets/Images/comida.jpg');
        }
        .tours {
            background-image: url('../../Assets/Images/buceo.webp');
        }
        .guide {
            background-image: url('../../Assets/Images/banana.jpg');
        }

        .CardItem__description {
            // visibility: visible;
            color: white;
            text-align: center;
            position: absolute;
            -moz-transition: all 0.2s ease-out 0s;
            transition: all 0.2s ease-out 0s;
            background-color: rgba(0, 0, 0, 0.5);
            display: flex;
            p {
                font-weight: bold;
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;
            }
        }

        // HOVER
        // .overlay:hover{
        //     -webkit-filter: brightness(60%);
        //     filter:brightness(60%);
        //     visibility: visible;
        // }
        // .CardItem__description:hover {
        //     visibility: visible;
        //     opacity: 1;
        // }
    }
}