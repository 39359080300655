// X-LARGE DEVICES [XL]
@media all and (min-width: 1200px) {
    .ActivitiesContainer {
        height: 100vh;
        padding-left: 0 !important;

        .ActivitiesTitle {
            font-size: 1.7em;
            padding-bottom: 2%;
            color: white;
        }
    }
}

// LARGE DEVICES [LG]
@media all and (min-width: 993px) and (max-width: 1199px) {
    .ActivitiesContainer {
        height: 100vh;
        padding-left: 0 !important;

        .ActivitiesTitle {
            font-size: 1.7em;
            padding-bottom: 2%;
            color: white;
        }
    }
}

// MEDIUM DEVICES [MD]
@media all and (min-width: 769px) and (max-width: 992px) {
    .ActivitiesContainer {
        height: 100vh;
        padding-left: 0 !important;

        .ActivitiesTitle {
            font-size: 1.7em;
            padding-bottom: 2%;
            color: white;
        }
    }
}

// SMALL DEVICES [SM]
@media all and (min-width: 577px) and (max-width: 768px) {
    .ActivitiesContainer {
        margin-top: 4em;
        height: 100vh;
        padding-left: 0 !important;

        .ActivitiesTitle {
            font-size: 1.7em;
            padding-bottom: 2%;
            color: white;
        }
    }
}

// XS DEVICES [-]
@media all and (min-width: 415px) and (max-width: 576px){
    .ActivitiesContainer {
        margin-top: 4em;
        height: 100vh;
        padding-left: 0 !important;

        .ActivitiesTitle {
            font-size: 1.7em;
            padding-bottom: 2%;
            color: white;
        }
    }
}

// IPHONE 6/7/8 PLUS DEVICES [AN EXCEPTION]
@media all and (max-width: 414px) {
    .ActivitiesContainer {
        margin-top: 4em;
        height: 100vh;
        padding-left: 0 !important;

        .ActivitiesTitle {
            font-size: 1.7em;
            padding-bottom: 2%;
            color: white;
        }
    }
}