.ModalOpen {
	position: fixed;
	top: 0;
	left: 0;
    background: rgba(0, 0, 0, 0.8);
	transition: all 500ms ease;
	opacity: 0;
	visibility: hidden;
    width: 100%;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
    z-index: 1;
}
#btn-modal:checked ~ .ModalOpen {
	opacity: 1;
	visibility: visible;
}
.contenedor {
	max-width: 460px;
	background-color: #111;
    color: white;
    padding: 60px 30px;
	box-shadow: 1px 7px 25px rgba(0,0,0,0.6);
	transition: all 500ms ease;
	position: relative;
}
.contenedor label{
	position: absolute;
    font-weight: bold;
	top: 10px;
	right: 10px;
	color: white;
	font-size: 15px;
	cursor: pointer;
}
#btn-modal{
	display: none;
}
.lbl-modal{
	background-color: rgb(187, 182, 169);
	padding: 10px 15px;
	border-radius: 4px;
	cursor: pointer;
    margin-top: 15px;
    color: white;
    font-weight: bold;
}
.lbl-modal:hover {
    filter: opacity(0.8);
    transform: scale(1.02);
}
.ModalText {
    padding: 0 20px;
    height: 400px;
    overflow-y: auto;
    text-align: left;
    font-weight: 500;
    color: white;
        h6 {
            font-weight: bold;
        }
}
.ModalText::-webkit-scrollbar {
    width: 1px;
    background-color: rgb(154, 154, 154);
}
.ModalText::-webkit-scrollbar-thumb {
background-color: #d13639;
}
@media only screen and (min-width:320px) and (max-width:768px){
	.contenedor{
		width: 80%;
	}
}