// X-LARGE DEVICES [XL]
@media all and (min-width: 1200px) {
    .MapRow {
        .MapContent {
            padding: 0 !important;
            .MapImg {
                height: 100vh;
                width: 100vw;
                background-size: cover;
                background-position: 50% 90%;
            }
        }
    }
}

// LARGE DEVICES [LG]
@media all and (min-width: 993px) and (max-width: 1199px) {
    .MapRow {
        .MapContent {
            padding: 0 !important;
            .MapImg {
                height: 100vh;
                width: 100vw;
                background-size: cover;
                background-position: 50% 90%;
            }
        }
    }
}

// MEDIUM DEVICES [MD]
@media all and (min-width: 769px) and (max-width: 992px) {
    .MapRow {
        .MapContent {
            padding: 0 !important;
            .MapImg {
                height: 100vh;
                width: 100vw;
                background-size: cover;
                background-position: 50% 90%;
            }
        }
    }
}

// SMALL DEVICES [SM]
@media all and (min-width: 577px) and (max-width: 768px) {
    .MapRow {
        .MapContent {
            padding: 0 !important;
            .MapImg {
                height: 100vh;
                width: 100vw;
                background-size: 145vw 100vh;
                background-position: 57%;
            }
        }
    }
}

// XS DEVICES [-]
@media all and (min-width: 415px) and (max-width: 576px){
    .MapRow {
        .MapContent {
            padding: 0 !important;
            .MapImg {
                height: 100vh;
                width: 100vw;
                background-size: 150vw 100vh;
                background-position: 57%;
            }
        }
    }
}

// IPHONE 6/7/8 PLUS DEVICES [AN EXCEPTION]
@media all and (max-width: 414px) {
    .MapRow {
        .MapContent {
            padding: 0 !important;
            .MapImg {
                height: 100vh;
                width: 100vw;
                background-size: 150vw 100vh;
                background-position: 57%;
            }
        }
    }
}