.Footer {
    background-color: rgb(187, 182, 169);
    color: white;
    font-weight: bold;
    .FooterText {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 !important;
        .Title {
            font-family: amalfi;
            text-transform: capitalize;
            color: white;
        }
        ul {
            list-style: none;
            display: flex;
            justify-content: space-evenly;

            li {
                text-transform: uppercase;
                .NavBarLink {
                    position: relative;
                    color: white;
                    text-decoration: none;
                    text-transform: uppercase;
                    transition: all 0.3s;
                    cursor: pointer;
                }
                .NavBarLink:hover {
                    border-bottom: 1px solid white;
                }
            }
        }
    }
}