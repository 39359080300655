// X-LARGE DEVICES [XL]
@media all and (min-width: 1200px) {
    .ImagesSlider {
        width: 500px;
        left: 0;

        .swiper-slide {
            padding-top: 100%;
            .BigImages {
                top: 0;
                left: 0;
                height: 100%;
            }
        }

        .swiper-button-prev {
            left: 30;
        }

        .swiper-button-next {
            right: 30;
        }
    }
}

// LARGE DEVICES [LG]
@media all and (min-width: 993px) and (max-width: 1199px) {
    .ImagesSlider {
        width: 420px;
        left: 0;

        .swiper-slide {
            padding-top: 100%;
            .BigImages {
                top: 0;
                left: 0;
                height: 100%;
            }
        }

        .swiper-button-prev {
            left: 30;
        }

        .swiper-button-next {
            right: 30;
        }
    }
}

// MEDIUM DEVICES [MD]
@media all and (min-width: 769px) and (max-width: 992px) {
    .ImagesSlider {
        width: 300px;
        height: 500px;
        left: 0;

        .swiper-slide {
            padding-top: 100%;
            .BigImages {
                top: 0;
                left: 0;
                height: 100%;
            }
        }

        .swiper-button-prev {
            left: 30;
        }

        .swiper-button-next {
            right: 30;
        }
    }
}

// SMALL DEVICES [SM]
@media all and (min-width: 577px) and (max-width: 768px) {
    .ImagesSlider {
        width: 75vw;
        height: 350px;
        left: 0;

        .swiper-slide {
            padding-top: 100%;
            .BigImages {
                top: 0;
                left: 0;
                height: 100%;
            }
        }

        .swiper-button-prev {
            left: 30;
        }

        .swiper-button-next {
            right: 30;
        }
    }
}

// XS DEVICES [-]
@media all and (min-width: 415px) and (max-width: 576px){
    .ImagesSlider {
        width: 85vw;
        height: 300px;
        left: 0;

        .swiper-slide {
            padding-top: 100%;
            .BigImages {
                top: 0;
                left: 0;
                height: 100%;
            }
        }

        .swiper-button-prev {
            left: 30;
        }

        .swiper-button-next {
            right: 30;
        }
    }
}

// IPHONE 6/7/8 PLUS DEVICES [AN EXCEPTION]
@media all and (max-width: 414px) {
    .ImagesSlider {
        width: 80vw;
        height: 50vh;
        left: 0;

        .swiper-slide {
            padding-top: 100%;
            .BigImages {
                top: 0;
                left: 0;
                height: 100%;
            }
        }

        .swiper-button-prev {
            left: 30;
        }

        .swiper-button-next {
            right: 30;
        }
    }
}