// X-LARGE DEVICES [XL]
@media all and (min-width: 1200px) {
    .NavBarContainer {
        height: 7em;
        padding-top: 3em;

        .NavBarRow {
            .NavBarLeftContent, .NavBarRightContent {
                padding-left: 8%;
                padding-right: 8%;
                ul {
                    padding: 0 !important;
                    li {
                        a {
                            font-weight: 700;
                            font-size: 1em;
                        }
                    }
                }
            }
            .BurgerMenuLabel {
                display: none;
            }
        }
    }
}

// LARGE DEVICES [LG]
@media all and (min-width: 993px) and (max-width: 1199px) {
    .NavBarContainer {
        width: 100%;
        height: 7em;
        padding-top: 3em;

        .NavBarRow {
            .NavBarLeftContent, .NavBarRightContent {
                padding-left: 8%;
                padding-right: 8%;
                ul {
                    padding: 0 !important;
                    li {
                        a {
                            font-weight: 700;
                            font-size: 1em;
                            padding: 0.25em;
                        }
                    }
                }
            }
            .BurgerMenuLabel {
                display: none;
            }
        }
    }
}

// MEDIUM DEVICES [MD]
@media all and (min-width: 769px) and (max-width: 992px) {
    .NavBarContainer {
        width: 100%;
        height: 7em;
        padding-top: 3em;

        .NavBarRow {
            .NavBarLeftContent, .NavBarRightContent {
                padding-left: 8%;
                padding-right: 8%;
                ul {
                    padding: 0 !important;
                    li {
                        a {
                            font-weight: 700;
                            font-size: 0.9em;
                            padding: 0.25em;
                        }
                    }
                }
            }
            .BurgerMenuLabel {
                display: none;
            }
        }
    }
}

// SMALL DEVICES [SM]
@media all and (min-width: 577px) and (max-width: 768px) {
    .NavBarContainer {
        width: 100%;
        height: 7em;
        padding-top: 3em;

        .NavBarRow {
            .NavBarLeftContent, .NavBarRightContent {
                display: none;
            }
        }
    }
}

// XS DEVICES [-]
@media all and (max-width: 576px) {
    .NavBarContainer {
        width: 100%;
        height: 7em;
        padding-top: 3em;

        .NavBarRow {
            .NavBarLeftContent, .NavBarRightContent {
                display: none;
            }
        }
    }
}