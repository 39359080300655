// X-LARGE DEVICES [XL]
@media all and (min-width: 1200px) {
    .TheVillaContainer {
        height: 100vh;

        .TheVillaRow {
            .TheVillaBkgColor {
                height: 80vh;
                z-index: 0;
                width: 75vw;
                margin-left: 24vw;
            }
            .TheVillaContent {
                z-index: 1;
                height: 100vh;

                .AlignmentDiv{
                    .TheVillaTextTitle {
                            h3 {
                                font-size: 1.7em;
                                padding-bottom: 2%;
                            }
                    }
                    .TheVillaLists {
                        .TheVillaList {
                            padding-left: 2%;
                            width: 33.3%;
                            li {
                                line-height: 2em;
                            }
                        }
                        .TheVillaListMobile {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}

// LARGE DEVICES [LG]
@media all and (min-width: 993px) and (max-width: 1199px) {
    .TheVillaContainer {
        height: 100vh;

        .TheVillaRow {
            .TheVillaBkgColor {
                height: 80vh;
                z-index: 0;
                width: 75vw;
                margin-left: 24vw;
            }
            .TheVillaContent {
                z-index: 1;
                height: 100vh;

                .AlignmentDiv{
                    .TheVillaTextTitle {
                            h3 {
                                font-size: 1.7em;
                                padding-bottom: 2%;
                            }
                    }
                    .TheVillaLists {
                        .TheVillaList {
                            padding-left: 0;
                            width: 33.3%;
                            li {
                                line-height: 1.8em;
                            }
                        }
                        .TheVillaListMobile {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}

// MEDIUM DEVICES [MD]
@media all and (min-width: 769px) and (max-width: 992px) {
    .TheVillaContainer {
        height: 100vh;

        .TheVillaRow {
            .TheVillaBkgColor {
                height: 80vh;
                z-index: 0;
                width: 75vw;
                margin-left: 24vw;
            }
            .TheVillaContent {
                z-index: 1;
                height: 100vh;

                .AlignmentDiv{
                    .TheVillaTextTitle {
                            h3 {
                                font-size: 1.7em;
                                padding-bottom: 2%;
                            }
                    }
                    .TheVillaLists {
                        margin-top: 2%;
                        .TheVillaList {
                            padding-left: 0;
                            width: 33.3%;
                            li {
                                line-height: 1.5em;
                            }
                        }
                        .TheVillaListMobile {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}

// SMALL DEVICES [SM]
@media all and (min-width: 577px) and (max-width: 768px) {
    .TheVillaContainer {
        height: 140vh;
        background-color: rgb(187, 182, 169);
        padding: 4em;

        .TheVillaRow {
            .TheVillaContent {
                z-index: 1;
                height: auto;
                padding-left: 6em;
                padding-right: 6em;
                margin-top: 4em;

                .AlignmentDiv{
                    .TheVillaTextTitle {
                            h3 {
                                display: flex;
                                justify-content: center;
                                font-size: 1.7em;
                                padding-bottom: 2%;
                            }
                    }
                    .TheVillaLists {
                        margin-top: 2%;
                        .TheVillaList {
                            padding-left: 0;
                            width: 33.3%;
                            li {
                                line-height: 1.5em;
                            }
                        }
                        .TheVillaListMobile {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}

// XS DEVICES [-]
@media all and (min-width: 415px) and (max-width: 576px){
    .TheVillaContainer {
        height: 140vh;
        background-color: rgb(187, 182, 169);

        .TheVillaRow {
            .TheVillaContent {
                z-index: 1;
                height: auto;
                padding-left: 3em;
                padding-right: 3em;

                .AlignmentDiv{
                    .TheVillaTextTitle {
                            h3 {
                                display: flex;
                                justify-content: center;
                                font-size: 1.7em;
                                padding-bottom: 2%;
                            }
                    }
                    .TheVillaLists {
                        margin-top: 2%;
                        .TheVillaList {
                            padding-left: 0;
                            width: 33.3%;
                            li {
                                line-height: 1.5em;
                            }
                        }
                        .TheVillaListMobile {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}

// IPHONE 6/7/8 PLUS DEVICES [AN EXCEPTION]
@media all and (max-width: 414px) {
    .TheVillaContainer {
        height: auto;
        background-color: rgb(187, 182, 169);
        padding: 4em;

        .TheVillaRow {
            .TheVillaContent {
                z-index: 1;
                height: auto;
                margin-top: 4em;

                .AlignmentDiv{
                    padding-left: 1em;
                    padding-right: 1em;

                    .TheVillaTextTitle {
                            h3 {
                                display: flex;
                                justify-content: center;
                                font-size: 1.7em;
                                padding-bottom: 2%;
                            }
                    }
                    .TheVillaLists {
                        margin-top: 2%;

                        .TheVillaList {
                            display: none;
                        }
                        .TheVillaListMobile {
                            padding-left: 0;
                            width: 50%;
                            li {
                                line-height: 1.7em;
                            }
                        }
                    }
                }
            }
        }
    }
}