// X-LARGE DEVICES [XL]
@media all and (min-width: 1200px) {
    .Footer {
        height: 11em;
        .FooterText {
            height: 11em;
            .Title {
                font-size: 1.3em;
                margin-top: 3%;
            }
            ul {
                width: 50vw;
                padding: 0;
                margin-top: 3%;

                li {
                    .NavBarLink:hover {
                        padding-bottom: 4px;
                    }
                }
            }
            .Copyright {
                font-size: 0.8em;
                .rm {
                    color: white;
                    text-decoration: none;
                }
            }
        }
    }
}

// LARGE DEVICES [LG]
@media all and (min-width: 993px) and (max-width: 1199px) {
    .Footer {
        height: 11em;
        .FooterText {
            height: 11em;
            .Title {
                font-size: 1.3em;
                margin-top: 3%;
            }
            ul {
                width: 50vw;
                padding: 0;
                margin-top: 3%;

                li {
                    .NavBarLink:hover {
                        padding-bottom: 4px;
                    }
                }
            }
            .Copyright {
                font-size: 0.8em;
                .rm {
                    color: white;
                    text-decoration: none;
                }
            }
        }
    }
}

// MEDIUM DEVICES [MD]
@media all and (min-width: 769px) and (max-width: 992px) {
    .Footer {
        height: 11em;
        .FooterText {
            height: 11em;
            .Title {
                font-size: 1.3em;
                margin-top: 3%;
            }
            ul {
                width: 80vw;
                padding: 0;
                margin-top: 3%;

                li {
                    .NavBarLink:hover {
                        padding-bottom: 4px;
                    }
                }
            }
            .Copyright {
                font-size: 0.8em;
                .rm {
                    color: white;
                    text-decoration: none;
                }
            }
        }
    }
}

// SMALL DEVICES [SM]
@media all and (min-width: 577px) and (max-width: 768px) {
    .Footer {
        height: 11em;
        .FooterText {
            height: 11em;
            .Title {
                font-size: 1.3em;
                margin-top: 3%;
            }
            ul {
                width: 80vw;
                padding: 0;
                margin-top: 3%;

                li {
                    font-size: 0.8em;
                    .NavBarLink:hover {
                        padding-bottom: 4px;
                    }
                }
            }
            .Copyright {
                font-size: 0.7em;
                .rm {
                    color: white;
                    text-decoration: none;
                }
            }
        }
    }
}

// XS DEVICES [-]
@media all and (min-width: 415px) and (max-width: 576px){
    .Footer {
        height: 11em;
        .FooterText {
            height: 11em;
            .Title {
                font-size: 1.3em;
                margin-top: 3%;
            }
            ul {
                width: 80vw;
                padding: 0;
                margin-top: 3%;
                margin-bottom: 0.5em !important;

                li {
                    font-size: 0.7em;
                    .NavBarLink:hover {
                        padding-bottom: 4px;
                    }
                }
            }
            .Copyright {
                font-size: 0.7em;
                .rm {
                    color: white;
                    text-decoration: none;
                }
            }
        }
    }
}

// IPHONE 6/7/8 PLUS DEVICES [AN EXCEPTION]
@media all and (max-width: 414px) {
    .Footer {
        height: 11em;
        .FooterText {
            height: 11em;
            .Title {
                font-size: 1.3em;
                margin-top: 1em;
            }
            ul {
                margin-top: 1em;
                width: 85vw;
                padding: 0;
                margin-bottom: 0.5em !important;
                li {
                    width: 85vw;
                    font-size: 0.6em;
                    .NavBarLink:hover {
                        padding-bottom: 4px;
                    }
                }
            }
            .Copyright {
                margin-top: 0.1em;
                font-size: 0.6em;
                width: 85vw;
                .rm {
                    color: white;
                    text-decoration: none;
                }
            }
        }
    }
}