.carousel {
    overflow: hidden;
    .CarouselItem {
        img {
            object-fit: cover;
        }
    }
    .H1Caption {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: amalfi;
        .word1 {
            font-family: amalfi;
            padding-top: 8%;
        }
    }
    .NavBar__center {
        position: absolute;
        text-align: center;
        text-transform: initial;
        text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.3);
        li {
            list-style: none;
            a {
                color: white;
                text-decoration: none;
                font-family: amalfi;
                text-align: center;
                span p {
                    font-family: quicksand;
                    text-transform: uppercase;
                    margin-top: 1%;
                    font-size: 0.5em !important;
                }
            }
        }
    }
}