.social-bar {
	position: fixed;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	z-index: 100;
    cursor: pointer;

    .icon {
        color: white;
        text-decoration: none;
        display: flex;
        transition: all .5s;
        background-color: rgb(143, 139, 130);
    }

    .icon-up:hover {
        color: white;
    }
    .icon-whatsapp:hover {
        color: #25D336;
    }
    .icon-airbnb:hover {
        color:  #FF5A5F;
    }
    .icon-instagram:hover {
        color: #DD2A7B;
    }
}