.ContactContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    .ContactRow {
        display: flex;
        align-items: center;
        box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 12px;
        .ContactHowToArrive {
            display: flex;
            align-items: center;

            .ContactTextContent {
                align-items: center;

                h3 {
                    font-family: amalfi;
                    text-transform: capitalize;
                }
                .HowToArriveText {
                    text-align: justify;
                }
                .info {
                    text-align: center;
                }
            }
        }

        .ContactForm {
            display: flex;

            .ContactFormContent {
                align-items: center;
                display: flex;
            }
        }
    }
}